import { FC } from "react";
import { StyledTextarea, StyledLabel } from "./TextArea.styled";

type Props = {
  label?: string;
  required?: boolean;
  name?: string;
  handleChange?: any;
  placeholder?: string; 
  type?: string;
  value?: string;
  readOnly?: boolean;
};

const TextArea: FC<Props> = ({ label, value, type,  placeholder,  required, handleChange, name, readOnly }) => {
  return (
    <>
      <StyledLabel>
        {label}
        {required && <span> *</span>}
      </StyledLabel>
      <StyledTextarea value={value} type={type} placeholder={placeholder} onChange={handleChange} name={name} rows={5} readOnly={readOnly || (value !== undefined && !handleChange)}/>
    </>
  );
};

export default TextArea;