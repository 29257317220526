import styled from "styled-components";
import { defaultBreakpoints } from "styled-media-query";

export const MarkUpWrapper = styled.div`
  a {
    width: 659px;
    width: 320px;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
  p {
    color: ${({ theme }) => theme.default.color.text.dark};
    font-family: ${({ theme }) => theme.default.fontFamily.secondaryFont};
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin: 24px 0;
    word-wrap: break-word;
    overflow-wrap: break-word; 
    @media (max-width: ${defaultBreakpoints.medium}) {
      font-size: 16px;
      line-height: 25px;
    }
  }  
  @media (max-width: ${defaultBreakpoints.medium}) {
    width: 95vw;
  }
`;
