import React, { FC, FocusEventHandler } from "react";
import {
  InputStyled,
  StyledLabel,
  InputError,
  WrapperInputError,
  InputWrapper,
  CheckBoxWrapper,
  InputBottomError
} from "./Input.styled";

type Props = {
  inputType?: "text" | "email" | "password" | "tel";
  placeholder?: string;
  label?: string;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  onBlur?: FocusEventHandler<HTMLInputElement> | undefined;
  register?: any;
  error?: string | undefined | false;
  name?: string;
  inputStyles?: string;
  required?: boolean;
  pattern?: string;
  prefix?: string;
  checkbox?: JSX.Element;
  bottom_error?: string | undefined | false;
  className?: string;
};

const Input: FC<Props> = ({
  inputType = "text",
  placeholder,
  handleChange,
  value,
  name,
  error,
  inputStyles = "default",
  onBlur,
  label,
  required,
  pattern,
  prefix,
  checkbox,
  bottom_error, 
  className
}) => {
  return (
    <>
      {error && (
        <WrapperInputError>
          <InputError>{error}</InputError>
        </WrapperInputError>
      )}
      <InputWrapper className={className}>
      <StyledLabel>
        {label}
        {required && <span> *</span>}
      </StyledLabel>
        {prefix && <span>{prefix}</span>}
        <InputStyled
          inputStyles={inputStyles}
          type={inputType}
          onBlur={onBlur}
          placeholder={placeholder}
          onChange={handleChange}
          autoComplete="off"
          value={value}
          name={name}
          data-testid="input"
          pattern={pattern}
        />
      {checkbox &&  <CheckBoxWrapper>{ checkbox}</CheckBoxWrapper>}
      </InputWrapper>
      {bottom_error && (
        <WrapperInputError>
          <InputBottomError>{bottom_error}</InputBottomError>
        </WrapperInputError>
      )}
    </>
  );
};

export default Input;
