import { FC, useContext, useEffect } from "react";
import Ad from "Components/Ad";
import { MPUWrapper, MPUInnerWrapper } from "./MPU.styled";
import { dfp } from "common/ads/dfp";
import { publicationTypes } from "common/context/Publication/PublicationContext";
import { PublicationContext } from "common/context/Publication";
import { useRouter } from "next/router";

interface Props {
  num?: number;
  className: string;
  id?: string;
}

const MPU: FC<Props> = ({ num, className, id }) => {
  const router = useRouter();
  const { config } = useContext<publicationTypes>(PublicationContext);
  return (
    <MPUWrapper>
      <MPUInnerWrapper>
        <Ad
          className={className}
          id={id ? id : `mpu${num}`}
          width="100%"
          height="200"
        />
      </MPUInnerWrapper>
    </MPUWrapper>
  );
};

export default MPU;
