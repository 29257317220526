import CenterWrapper from "Components/CenterWrapper";
import Meta from "Components/Meta";
import Title from "Components/Title";
import { useRouter } from "next/router";

import { FC, ReactElement, useEffect, useState } from "react";
import Frame from "../../Frame";
import { LayoutProps } from "../../types/Layout.types";
import Body from "../ArticleBody/Body";
import { ArticleProps } from "../types/Article.types";
import AdvertiseWithUsForm from "./components/AdvertiseWithUsForm";
import { ServiceWrapper } from "./Service.styled";

const BodySuffix: FC<{ alias: string }> = ({ alias }) => {
  const components: Record<string, any> = {
    "advertise-with-us": AdvertiseWithUsForm,
  };

  if (!components[alias]) {
    return null;
  }

  const Component = components[alias];

  return <Component />;
};

interface Props {
  article: ArticleProps;
  menus: LayoutProps;
}

const Service: FC<Props> = ({ article, menus }) => {
  const { query } = useRouter();

  const alias = (() => {
    const tAlias = `${query.service_id}`.split("/").pop() || "";

    // removes the ID from the alias if present
    return tAlias
      .split("-")
      .filter((item) => item !== article.data.id)
      .join("-");
  })();

  return (
    <Frame data={menus} title={article?.data.title}>
      <Meta />
      <CenterWrapper>
        <Title type="service">{article.data.title}</Title>
        <ServiceWrapper>
          <Body body={article.data.body} />
          <BodySuffix alias={alias} />
        </ServiceWrapper>
      </CenterWrapper>
    </Frame>
  );
};

export default Service;
