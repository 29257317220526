import styled from "styled-components";
import { defaultBreakpoints } from "styled-media-query";

export const Info = styled.p`
  font-family:  ${({ theme }) => theme.default.fontFamily.secondaryFont};
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 32px;

  @media (max-width: ${defaultBreakpoints.medium}) {
    margin-bottom: 24px;
  }

  .bold {
    font-weight: 700;
  }
`;

export const UploadWrapper = styled.div`
  display: flex;
  margin: 0 0 0 auto;
  min-height: 180px;

  @media (max-width: ${defaultBreakpoints.medium}) {
    flex-direction: column;
    margin: 0;
  }

`
export const TextAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
   width: 65%;
   margin-right: 16px;

  @media (max-width: ${defaultBreakpoints.medium}) {
      width: 100%;
      margin-right: 0;
  }
`


export const EmailWrapper = styled.div`
  margin: 0 0 16px 0;
`

export const ButtonWrapper = styled.div`
  margin: 16px 0 32px 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;

  @media (max-width: ${defaultBreakpoints.medium}) {
    align-items: flex-start;
    justify-content: flex-start;
  }

`
export const LegalWrapper = styled.span`
font-family: ${({ theme }) => theme.default.fontFamily.secondaryFont};
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 16px;

  a {
    text-decoration: underline;
    color: ${({ theme }) => theme.default.color.text.dark};
  }
`;
