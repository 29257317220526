import { PublicationContext } from "common/context/Publication";
import { publicationTypes } from "common/context/Publication/PublicationContext";
import Head from "next/head";
import { useContext } from "react";

const Meta = () => {
  const { config } =
    useContext<publicationTypes>(PublicationContext);

  return (
    <Head>
      <script
        dangerouslySetInnerHTML={{
          __html: `!function(a,l,b,c,k,s,t,g,A){a.CustomerConnectAnalytics=k,a[k]=a[k]||function(){
            (a[k].q=a[k].q||[]).push(arguments)},g=l.createElement(b),A=l.getElementsByTagName(b)[0],
              g.type="text/javascript",g.async=!0,g.src=c+"?id="+s+"&parentId="+t,A.parentNode.insertBefore(g,A)
            }(window,document,"script","//carbon-cdn.ccgateway.net/script","cca",window.location.hostname,"3028f7536b");
        `,
        }}
      />
      {/* Google Tag Manager */}
      <script dangerouslySetInnerHTML={{
        __html: `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${config.gtm_id}');
    ` }} />
      {/* End Google Tag Manager */}
    </Head>
  )
}
export default Meta;
