import styled from "styled-components";
import { defaultBreakpoints } from "styled-media-query";
import { transparentize } from "polished";

export const ServiceWrapper = styled.div`
  margin-bottom: 120px;
  p {
    font-size: 19px;
    color: #212224;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    @media (max-width: ${defaultBreakpoints.medium}) {
      font-size: 16px;
      line-height: 27px;
      letter-spacing: 0;
    }
  }
  li {
    padding: 5px 0;
  }
  table {
    border: 1px solid grey;
    width: 100%;
    border-collapse: collapse;
    p {
      text-align: center;
    }
  }
  td {
    padding: 0;
    border: 1px solid grey;
    text-align: center;
  }
  th {
    background-color: ${({ theme }) => theme.default.color.backgrounds.light};
    border: 1px solid grey;
    text-align: center;
    font-weight: bold;
  }
  a {
    color: ${({ theme }) => theme.default.color.primary};
    :hover {
      color: ${({ theme }) => transparentize(0.2, theme.default.color.primary)};
      text-decoration: underline;
    }
  }
`;
