import { FC } from "react";
import { useEffect } from "react";
import { useRouter } from "next/router";

type Props = {
  destination: string;
};

const Redirect: FC<Props> = ({ destination }) => {
  const { push } = useRouter();
  useEffect(() => {
    push(destination ? destination : "/");
  }, []);

  return <></>;
};

export default Redirect;
