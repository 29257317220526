import styled, { css } from "styled-components";

type StyledProps = {
  type?: string;
};
export const FormStyle = css`
  resize: none;
  width: 100%;
  overflow: hidden;
  outline: none;
  border: ${({ theme }) => `1px solid ${theme.default.color.text.light}`};
  background-color: ${({ theme }) => theme.default.color.backgrounds.white};
  font-family: ${({ theme }) => theme.default.fontFamily.primaryFont};
  border-radius: 20px;
  padding: 1rem;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-align: left;

  &:focus {
    border: ${({ theme }) =>  `2px solid ${theme.default.color.primary}`};
  }

  &:hover {
   border: ${({ theme }) =>  `1px solid ${theme.default.color.primary}`};
  }
`;

export const CommentStyle = css`
  resize: none;
  width: 100%;
  overflow: hidden;
  outline: none;
  border: none;
  background-color: ${({ theme }) => theme.default.color.backgrounds.light};
  padding: 1rem;
  font-family: ${({ theme }) => theme.default.fontFamily.primaryFont};
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  border-top: 1px solid ${({ theme }) => theme.default.color.text.medium};
  text-align: left;

  &:focus {
    border: ${({ theme }) =>  `2px solid ${theme.default.color.primary}`};
  }
`;

export const StyledTextarea = styled.textarea<StyledProps>`
  ${({ type }) => type === "form" && FormStyle}
  ${({ type }) => type === "comment" && CommentStyle}
`;

export const StyledLabel = styled.p`
  font-weight: bold;
  font-family: ${({ theme }) => theme.default.fontFamily.primaryFont};
  span {
    color: ${({ theme }) => theme.default.color.primary};
  }
`;
