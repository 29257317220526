import React, { createContext, ReactNode, useState, FC, useMemo } from "react";
import { ArticleProps, refactoredArticleTypes } from "../types/Article.types";
import { optimiseImage } from "../../../Utils/crop";

type Props = {
  children: ReactNode;
  article: ArticleProps;
  publication: string;
};

export interface articleTypes {
  refactoredArticle: refactoredArticleTypes;
  memoizedArticleBody: any;
  isHeroOverride?: boolean;
  isDisableOutbrain?: boolean;
}

export const Context = createContext({} as any);

const extractFirstParaGraph = (body: any) => {
  let firstParagraph: any = "";
  try {
    body.forEach((element: any) => {
      if (firstParagraph === "") {
        if (element.type === "markup")
          firstParagraph = element.data.markup.replace(/<[^>]*>?/gm, "");
      }
    });
    return firstParagraph;
  } catch (err) {
    console.log(err);
    return firstParagraph;
  }
};
export const Provider: FC<Props> = ({ children, article, publication }) => {
  const image = article?.data.hero ? article?.data.hero[0]?.data?.url : "";
  const image_width: number = article?.data.hero
    ? article?.data.hero[0]?.data?.extra?.imageSize?.width || 1200
    : 1200;
  const social_image_width: number = article?.data.OGImages
    ? article?.data.OGImages[0]?.data?.extra?.imageSize?.width
    : image_width;
  const social_image = article.data.OGImages
    ? article?.data.OGImages[0]?.data?.url
    : image;
  const caption = article?.data.hero
    ? article?.data.hero[0]?.data?.caption
    : "";

  const premium = article?.data?.premium;
  const social_caption = article?.data.OGImages
    ? article?.data.OGImages[0]?.data?.caption
    : caption;
  const tags = article.data?.topics;
  const gallery_image = article?.data.hero
    ? article?.data.hero[0].data?.gallery &&
      article?.data.hero[0].data?.gallery[0]?.data
      ? article?.data.hero[0].data?.gallery[0]?.data.url
      : null
    : null;
  const gallery_image_width = article?.data.hero
    ? article?.data.hero[0].data?.gallery &&
      article?.data.hero[0].data?.gallery[0]?.data
      ? article?.data.hero[0].data?.gallery[0]?.data.extra.imageSize.width
      : null
    : null;
  const hasHeroImage = article?.data?.hero?.find(item => item.type === 'image');

  const social_gallery_image = article.data.OGImages
    ? article?.data.OGImages[0]?.data?.url
    : gallery_image;
  const social_gallery_image_width = article?.data.OGImages
    ? article?.data.OGImages[0]?.data?.extra?.imageSize.width
    : gallery_image_width;
  const body = extractFirstParaGraph(article?.data.body);
  const cropDefaultHeroSocialImage = article?.data.hero
    ? article?.data.hero[0]?.data?.extra?.sizes?.sixteenbynine
    : "";
  const cropData =
    (article.data.OGImages &&
      article.data.OGImages[0].data?.extra?.sizes?.sixteenbynine) ||
    cropDefaultHeroSocialImage;
  /* Filter hero array by priority */
  const priorityList = [
    "dailymotion",
    "youtube",
    "video-youtube",
    "gallery",
    "image",
  ];
  const priorityHero =
    article?.data.hero &&
    article?.data.hero
      .filter((e) => priorityList.includes(e.type))
      .sort(
        (a, b) => priorityList.indexOf(a.type) - priorityList.indexOf(b.type)
      );

  const refactoredArticle = useMemo(() => {
    return {
      lead: article.data.lead,
      sponsorPrefix: article.data.sponsorPrefix,
      sponsorURL: article.data?.sponsorURL,
      authors: article.data.authors,
      publish: article.data.publish,
      datePreference: article.data.extra?.displayDatePreference,
      updated: article.data.updated,
      sponsorImage: article?.data.sponsorImage,
      sidebar: article.data.sidebar?.layoutSettings,
      titleSocial: article?.data?.titleSocial,
      hierarchy: article.data.hierarchy,
      path: article.data.path,
      isVideo:
        article?.data.hero &&
        article?.data.hero[0].type === ("youtube" || "video-youtube"),
      isYoutube:
        article?.data.hero && article?.data.hero[0].type === "video-youtube",

      overrideDate: article.data.overrideDate,
      dailyMotion:
        article?.data.hero && article?.data.hero[0].type === "dailymotion",
      articleTypes: article.data.articleTypes,
      publications: article.data.publications,
      hero: article.data.hero && priorityHero[0],
      showComments: article.data.commenting,
      hideNewsLetter:
        article.data?.extra && article.data?.extra?.isHideNewsletterSignUp,
      type: article.data.articleTypes && article.data.articleTypes[0]?.name,
      url:
        article.data.publications && article.data.publications.length === 2
          ? article.data.publications[1] &&
            `${article.data.publications[1].url}${article.data?.path}`
          : article.data.publications[0] &&
            `${article.data.publications[0].url}${article.data?.path}`,
      title: article?.data.title,
      meta_title: `${
        article?.data.titleSEO ? article?.data.titleSEO : article?.data.title
      } | ${publication}`,
      structuredDataMetaTitle: `${
        article?.data.titleSEO ? article?.data.titleSEO : article?.data.title
      }`,
      images: [
        image
          ? `${image}?width=400&height=400&crop=1:1,smart&quality=75`
          : `${gallery_image}?width=400&height=400&crop=1:1,smart&quality=75`,
      ],
      section:
        article?.data.hierarchy && article?.data.hierarchy.length > 1
          ? article?.data.hierarchy[1].name
          : article?.data.hierarchy[0].name,
      keywords: article?.data?.keywords ? article?.data?.keywords : "",
      datePublished: `${new Date(
        article?.data.firstCreated * 1000
      ).toISOString()}`,
      site_name:
        article.data.publications && article.data.publications.length === 2
          ? article.data.publications[1] && article.data.publications[1].name
          : article.data.publications[0] && article.data.publications[0].name,
      dateModified: `${new Date(article?.data.updated * 1000).toISOString()}`,
      authorName: article?.data.authors
        ? article?.data.authors.map((author: any) => author.name)
        : [],
      dateCreated: `${new Date(
        article?.data.firstCreated * 1000
      ).toISOString()}`,
      publisherName: `${publication}`,
      description: `${
        article?.data?.lead && article?.data?.lead !== ""
          ? article?.data?.lead.replace(/<\/?[^>]+(>|$)/g, "")
          : body.replace(/"/g, "")
      }`,
      articleBody: article?.data.body && article?.data.body,
      body: body,
      topics: article.data?.topics,
      breadCrumbs:
        article.data.articleTypes &&
        article?.data?.hierarchy.map((breadCrumb: any, i: number) => ({
          position: i + 1,
          name: breadCrumb.name,
          item:
            article.data.publications && article.data.publications.length === 2
              ? article.data.publications[1] &&
                `${article.data.publications[1].url}${breadCrumb.path}`
              : article.data.publications[0] &&
                `${article.data.publications[0].url}${breadCrumb.path}`,
        })),
      openGraphImages: [
        {
          url: social_image
            ? `${optimiseImage(
                social_image,
                cropData,
                social_image_width
              )}&crop=16:9,smart&quality=75`
            : hasHeroImage ?  
            `${optimiseImage(
              hasHeroImage?.data?.url,
              cropData,
              hasHeroImage?.data?.extra?.imageSize?.width
            )}&crop=16:9,smart&quality=75`
            :  `${optimiseImage(
                social_gallery_image,
                cropData,
                social_gallery_image_width
              )}&crop=16:9,smart&quality=75`,
          alt: social_caption,
        },
      ],
      sectionName: article?.data.hierarchy && article?.data.hierarchy[0].name,
      tags: tags && tags.map((tag: any) => tag.name),
      chartBeatName:
        article.data?.articleTypes && article.data?.articleTypes[0]?.name
          ? article?.data?.articleTypes[0].name
          : "",
      chartBeatAuthor: article.data.authors
        ? article.data.authors[0].name
        : "unkown author",
      id: article.data.id,
      premium,
      isLiveBlog: article.data.isLiveBlog,
      liveblog: article.data.extra,
    };
  }, [article]);

  const isHeroOverride = useMemo(() => {
    try {
      if (article.data.extra && article.data.extra?.isHeroPortrait) {
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  }, [article]);

  const isDisableOutbrain = useMemo(() => {
    try {
      if (article.data.extra && article.data.extra?.disableOutbrain) {
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  }, [article]);

  const memoizedArticleBody = useMemo(() => {
    try {
      if (article && article.data && article.data.body) {
        let markupCount = 0;
        let mpuCount = 0;
        let hasOutbrainInserted = false;
        let hasTeadsInserted = false;

        const bodyWithAds = article.data.body.reduce((acc: any, item, index) => {
          acc.push(item);

          if (item.type === "markup") {
            markupCount++;

            // Insert Outbrain after 2nd markup
            if (markupCount === 2 && !hasOutbrainInserted) {
              acc.push({
                type: "mpu_outbrain",
                data: { id: "outbrain_1" }
              });
              hasOutbrainInserted = true;
            }

            // Insert Teads 2 markups after Outbrain
            else if (markupCount === 4 && !hasTeadsInserted) {
              acc.push({
                type: "teads",
                data: { id: "teads_1" }
              });
              hasTeadsInserted = true;
            }

            // Insert MPU ad every 4 markups after Teads
            else if (hasTeadsInserted && (markupCount - 4) % 4 === 0) {
              mpuCount++;
              acc.push({
                type: "mpu",
                data: {
                  count: mpuCount,
                  id: `mpu_${mpuCount}`
                }
              });
            }
          }
          return acc;
        }, []);

        return bodyWithAds;
      }
      return [];
    } catch (error) {
      console.error("Error occurred while retrieving article body:", error);
      return [];
    }
  }, [article]);

  const configContext = {
    refactoredArticle,
    memoizedArticleBody,
    isHeroOverride,
    isDisableOutbrain,
  };

  return <Context.Provider value={configContext}>{children}</Context.Provider>;
};
