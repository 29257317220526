import { FC, useMemo } from "react";
import { DailyMotionWrapper } from "./DailyMotion.styled";

interface Props {
  isHero?: boolean;
  post?: boolean;
  data?: {
    url: string;
    localCaption?: string;
    id?: string;
  };
}

const DailyMotion: FC<Props> = ({ data, isHero = false, post }) => {
  const motionid = data?.url && data.url.split("/")[4];
  const caption = data?.localCaption;

  const className = useMemo(() => {
    try {
      if (isHero) {
        return "video_wrapper";
      } else if (post) {
        return "video_wrapper_post";
      } else {
        return "video_wrapper";
      }
    } catch (e) {
      console.log(e);
    }
  }, [data, isHero, post]);

  if (!data) {
    return null;
  }
  return (
    <>
      <style jsx>{`
        .caption {
          font-family: Koh;
          font-style: normal;
          font-weight: normal;
          font-size: 15px;
          line-height: 17px;
          color: #858585;
          margin: 4px;
        }
        .video_wrapper {
          margin: 1rem auto;
          position: relative;
          height: ${isHero ? "100%" : "400px"};
          display: flex;
          flex-direction: column;
          margin-bottom: 20px;
        }
        .video_wrapper_post {
          margin: 1rem auto;
          position: relative;
          height: 310px;
          display: flex;
          flex-direction: column;
          margin-bottom: 20px;
        }

        .video_hero_wrapper {
          margin: 1rem auto;
          position: relative;
          height: 100%;
          display: flex;
          flex-direction: column;
          margin-bottom: 20px;
        }
        @media (max-width: 820px) {
          .video_wrapper {
            width: 95vw;
            height: 212px;
          }
          .video_hero_wrapper {
            width: 95vw;
            height: 212px;
          }
        }
      `}</style>
      <div
        //  className={isHero ? "video_hero_wrapper" : "video_wrapper"}
        className={className}
      >
        <iframe
          frameBorder={0}
          style={{
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            overflow: "hidden",
          }}
          src={`https://geo.dailymotion.com/player/x8po4.html?video=${
            data.id ? data.id : motionid
          }`}
          width="100%"
          height="100%"
          allowFullScreen
          allow="autoplay"
        ></iframe>

        {caption && <p className="caption">{caption}</p>}
      </div>
    </>
  );
};

export default DailyMotion;
