import { css } from "styled-components";
import styled from "styled-components";



export const ButtonPrimaryStyle = css<StyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 15px;
  border: 0;
  font-family: ${({ theme }) => theme.default.fontFamily.primaryFont};
  text-decoration: none;
  color: ${({ theme }) => theme.default.color.text.white};
  background-color: ${({ theme }) => theme.default.color.primary};
  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
    font-weight: bold;
  }
  width: ${({ fullwidth }) => fullwidth ?  "100%" : '150px'};
`;

export const ButtonSecondaryStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 15px;
  border: 0;
  border-radius: 20px;
  color: white;
  font-family: ${({ theme }) => theme.default.fontFamily.primaryFont};
  background-color: red;
  text-decoration: none;
  width: 100%;
  &:disabled {
    cursor: not-allowed;
    background-color: gray;
  }
`;

export const ButtonClearStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 15px;
  border: 0;
  border-radius: 20px;
  font-size: 1.2rem;
  font-family: Standard Sans, sans-serif;
  background-color: white;
  text-decoration: none;
  &:disabled {
    cursor: not-allowed;
    background-color: gray;
  }
`;
export const ButtonFrostStyle = css<StyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 15px;
  border: 0;
  font-family: Standard Sans, sans-serif;
  text-decoration: none;
  color:  ${({ disabled }) => disabled ? "#858585" : "white"};

  background-color: ${({ theme }) => theme.default.color.text.light};

  &:disabled {
    cursor: not-allowed;
    background-color: #e7e7e7;
    font-weight: bold;
  }
`;

export const PaggingationStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 15px;
  border: 0;
  font-family: Standard Sans, sans-serif;
  text-decoration: none;
  text-transform: uppercase;
  background-color: white;
  border: 1px solid gray;
  &:disabled {
    cursor: not-allowed;
    border: 1px solid #d4d4d4;
    :hover {
      background-color: white;
    }
  }
  :hover {
    background-color: #f3f3f3;
  }
`;

type StyledProps = {
  buttonStyle?: string;
  fullwidth?: boolean;
  disabled?:boolean;
};

export const StyledButton = styled.button<StyledProps>`
  ${({ buttonStyle }) => buttonStyle === "primary" && ButtonPrimaryStyle}
  ${({ buttonStyle }) => buttonStyle === "secondary" && ButtonSecondaryStyle}
  ${({ buttonStyle }) => buttonStyle === "clear" && ButtonClearStyle}
  ${({ buttonStyle }) => buttonStyle === "frost" && ButtonFrostStyle}
  ${({ buttonStyle }) => buttonStyle === "pagination" && PaggingationStyle}
  outline: 0;
  border-radius: 24px;
  white-space: nowrap;
  svg {
    vertical-align: text-top;
    font-size: 20px;
  }
  &:hover {
    cursor: pointer;
    &:disabled {
      cursor: not-allowed;
    }
  }
  width: ${({ fullwidth }) => fullwidth ?  "100%" : '150px'};
`;
