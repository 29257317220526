import { FC } from "react";
import parse from "html-react-parser";
import { MarkUpWrapper } from "./MarkUp.styled";

interface Props {
  markup: string;
}

const MarkUp: FC<Props> = ({ markup }) => {
  if (!markup) {
    return null;
  }
  return <MarkUpWrapper>{parse(markup)}</MarkUpWrapper>;
};

export default MarkUp;
