import dynamic from "next/dynamic";
import { FC, useContext } from "react";

import TEADS from "common/Layout/Adverts/TEADS";
import { PublicationContext } from "common/context/Publication";
import { publicationTypes } from "common/context/Publication/PublicationContext";
import DailyMotion from "../../components/DailyMotion";
import MarkUp from "../../components/MarkUp";
import { ArticleBodyProps } from "../../types/Article.types";
import { articleTypes } from "../../Context/ArticleContext";
import { ArticleContext } from "../../Context";

const ImageGallery = dynamic(() => import("../../components/ImageGallery"));
const ArticleImage = dynamic(() => import("../../ArticleImage"));
const Tweet = dynamic(() => import("../../components/Tweet"), { ssr: false });
const RelatedArticle = dynamic(() => import("../../components/RelatedArticle"));
const InfoBox = dynamic(() => import("../../components/InfoBox"));
const Divider = dynamic(() => import("Components/Divider"));
const Table = dynamic(() => import("../../components/Table"));
const Youtube = dynamic(() => import("../../components/YouTube/YouTube"));
const TikTok = dynamic(() => import("../../components/TikTok"));
const FaceBook = dynamic(() => import("../../components/FaceBook"));
const BlockQuote = dynamic(() => import("../../components/Blockquote"));
const Instagram = dynamic(() => import("../../components/Instagram"));
const ArticleButton = dynamic(() => import("../../components/ArticleButton"));
const Iframe = dynamic(() => import("../../components/Iframe"));
const Html = dynamic(() => import("../../components/Html"));
const NumberBox = dynamic(() => import("../../components/NumberBox"));
const MPU = dynamic(() => import("common/Layout/Adverts/MPU"), {
  ssr: false,
});
const Ad = dynamic(() => import("Components/Ad"));
const OutBrain = dynamic(() => import("common/Layout/Adverts/OutBrain"), {
  ssr: false,
});
interface Props {
  body: ArticleBodyProps[];
  type?: string;
  className?: string;
}

const Body: FC<Props> = ({ body, type, className }) => {
  const {
    publication,
    config: { poool_app_id: pooolAppId },
  } = useContext<publicationTypes>(PublicationContext);
  const { isDisableOutbrain } = useContext<articleTypes>(ArticleContext);

  const pub = publication || "";

  if (!body) {
    return null;
  }

  return (
    <>
      <div className="content" data-poool="80" data-poool-mode="hide">
        <div {...{ className }}>
          {body.map((bodyItem: any, i: number) => {
            const { type, data } = bodyItem;

            switch (type) {
              case "video-dm":
                return (
                  <div key={i}>
                    <DailyMotion data={data} />
                  </div>
                );
              case "mpu_outbrain":
                return (
                  <div key={data.id}>
                    {!isDisableOutbrain && <OutBrain id="AR_2" />}
                  </div>
                );

              case "teads":
                return (
                  <div key={data.id}>
                    <TEADS id="teads" className="article_mpu" />
                  </div>
                );

              case "markup":
                return (
                  <div key={`markup_${i}`}>
                    <div>
                      <MarkUp key={i} markup={data.markup} />
                    </div>
                  </div>
                );

              case "mpu":
                return (
                  <div key={data.id}>
                    <MPU
                      className="article_mpu"
                      type="articlempu"
                      num={data.count}
                    />
                  </div>
                );

              case "H1":
                return (
                  <div key={i}>
                    <MarkUp key={i} markup={data.markup} />
                  </div>
                );
              case "H2":
                return (
                  <div key={i}>
                    <MarkUp key={i} markup={data.markup} />
                  </div>
                );
              case "H3":
                return (
                  <div key={i}>
                    <MarkUp key={i} markup={data.markup} />
                  </div>
                );
              case "H4":
                return (
                  <div key={i}>
                    <MarkUp key={i} markup={data.markup} />
                  </div>
                );
              case "H5":
                return (
                  <div key={i}>
                    <MarkUp key={i} markup={data.markup} />
                  </div>
                );
              case "H6":
                return (
                  <div key={i}>
                    <MarkUp key={i} markup={data.markup} />
                  </div>
                );
              case "listnumbered":
                return (
                  <div key={i}>
                    <MarkUp key={i} markup={data.markup} />
                  </div>
                );
              case "listbulleted":
                return (
                  <div key={i}>
                    <MarkUp key={i} markup={data.markup} />
                  </div>
                );
              case "related":
                return (
                  <div key={i}>
                    <RelatedArticle data={data} />
                  </div>
                );
              case "twitter":
                return (
                  <div key={i}>
                    <Tweet url={data.url} />
                  </div>
                );
              case "numberbox":
                return (
                  <div key={i}>
                    <NumberBox data={data} />
                  </div>
                );
              case "HR":
                return (
                  <div key={i}>
                    <Divider key={i} direction="horizontal" />
                  </div>
                );
              case "infobox":
                return (
                  <div key={i}>
                    <InfoBox key={i} data={data} />
                  </div>
                );
              case "video-youtube":
                return (
                  <div key={i}>
                    <Youtube key={i} data={data} />
                  </div>
                );
              case "instagram":
                return (
                  <div key={i}>
                    <Instagram key={i} url={data.url} />
                  </div>
                );
              case "blockquote":
                return (
                  <div key={i}>
                    <BlockQuote data={data} key={i} />
                  </div>
                );
              case "tiktok":
                return (
                  <div key={i}>
                    <TikTok url={data.url} key={i} />
                  </div>
                );
              case "facebook":
                return (
                  <div key={i}>
                    <FaceBook url={data.url} key={i} />
                  </div>
                );
              case "table":
                return (
                  <div key={i}>
                    <Table key={i} markup={data.markup} />
                  </div>
                );
              case "html":
                return (
                  <div key={i}>
                    <Html key={i} data={data} />
                  </div>
                );
              case "iframe":
                return (
                  <div key={i}>
                    <Iframe key={i} data={data} />
                  </div>
                );
              case "button":
                return (
                  <div key={i}>
                    <ArticleButton key={i} data={data} />
                  </div>
                );
              case "image":
                return (
                  <div key={i}>
                    <ArticleImage image={data} />
                  </div>
                );
              case "gallery":
                return (
                  <div key={i}>
                    <ImageGallery data={data} />
                  </div>
                );
              default:
                return null;
            }
          })}
        </div>
      </div>
      {pooolAppId && (
        <>
          <script
            id="poool-access"
            src="https://assets.poool.fr/access.min.js"
            async
          />
          <script
            type="text/javascript"
            dangerouslySetInnerHTML={{
              __html: `
                document.addEventListener('DOMContentLoaded', () => {
                  const script = document.querySelector('#poool-access')
                  const init = () => {
                    let access = Access.init('${pooolAppId}');
                    access.config({
                      cookies_enabled: true,
                      cookies_domain: '.${pub}',
                      popover_timeout: -1,
                      default_widget: 'invisible',
                      gtm_auto_tracking_enabled: true,
                      auto_tracking_spec_v2: true,
                      disable_content_height_calculation: true,
                    });
                    access.createPaywall({
                      target: '#poool-widget',
                      content: '[data-poool]',
                      mode: 'hide',
                      percent: 80,
                      pageType: 'premium',
                    });
                  }
                  if(window.Access) {
                    init()
                   } else {
                    script.onload = () => {
                      init();
                    }
                 }
                });
           `,
            }}
            async
          />

          <script
            async
            id="poool-audit"
            src="https://assets.poool.fr/audit.min.js"
          ></script>
          <script
            async
            type="text/javascript"
            dangerouslySetInnerHTML={{
              __html: `
              document.addEventListener('DOMContentLoaded', () => {
                const script = document.getElementById('poool-audit');

                script.addEventListener('load', function () {
                  Audit
                    .init('${pooolAppId}')
                    .config({
                      cookies_enabled: true,
                      cookies_domain: '.${pub}',
                      gtm_auto_tracking_enabled: true,
                      auto_tracking_spec_v2: true,
                    })
                    .sendEvent('page-view', { type: 'premium' });
                });
              });
           `,
            }}
          />
        </>
      )}
      <div id="poool-widget" />
    </>
  );
};

export default Body;
