import { FC, ReactNode } from "react";
import { StyledButton } from "./Button.styled";
import ClipLoader from "react-spinners/ClipLoader";

interface Props {
  children?: ReactNode | string;
  handleClick?: () => void;
  disabled?: boolean;
  buttonStyles?: string;
  buttonType?: "button" | "submit";
  isLoading?: boolean;
  icon?: ReactNode;
  lefticon?: ReactNode;
  type?: string;
  fullwidth?: boolean;
  loading?: boolean;
  className?: string;
}

export const Button: FC<Props> = ({
  children,
  handleClick,
  disabled,
  buttonStyles,
  buttonType = "button",
  icon,
  fullwidth,
  loading,
  lefticon,
  className
}) => (
  <StyledButton
    type={buttonType}
    onClick={handleClick}
    disabled={disabled}
    buttonStyle={buttonStyles}
    role="button"
    fullwidth={fullwidth}
    className={className}
  >
    {lefticon}
    {loading ? (
      <span>
        <ClipLoader color="white" loading={loading} size={12} /> loading
      </span>
    ) : (
      children
    )}
    {icon}
  </StyledButton>
);

export default Button;
