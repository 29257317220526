import styled from "styled-components";
import { defaultBreakpoints } from "styled-media-query";


export const Info = styled.p`
  font-family:  ${({ theme }) => theme.default.fontFamily.secondaryFont};
`;

export const UploadImageTitle = styled.strong`
 font-family:  ${({ theme }) => theme.default.fontFamily.secondaryFont};
`;

export const LegalWrapper = styled.span`
font-family: ${({ theme }) => theme.default.fontFamily.secondaryFont};
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 16px;

  a {
    text-decoration: underline;
    color: ${({ theme }) => theme.default.color.text.dark};
  }
`;


export const InputWrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  margin-bottom: 1rem;
  row-gap: 16px;
  div {

    @media (max-width: ${defaultBreakpoints.medium}) {
      margin: 0;
      width: 100%;
    }
  }
  @media (max-width: ${defaultBreakpoints.medium}) {
    width: 100%;
  }
`;

export const InnerInputWrapper = styled.div`
  display: flex;
  width: 100%;

  .input-name {
    margin-right: auto;
  }
  
  .input-name, .input-company {
    width: 49%;
  }

  .input-address {
    width: 58%;
    margin-right: auto;
  }
  .input-phone {
    width: 40%;
  }

  @media (max-width: ${defaultBreakpoints.medium}) {
    flex-direction: column;

    .input-name, .input-address, .input-company, .input-phone {
      margin-right: 0;
      width: 100%;
    }

    .input-name, .input-address {
      margin-bottom: 16px;
    }

  }
  

`

export const SelectWrapper = styled.div`
  width: 45%;
  margin-bottom: 1rem;
  @media (max-width: ${defaultBreakpoints.medium}) {
    width: 100%;
  }
`;

export const Title = styled.h2`
  
`;

export const EnquiryWrapper = styled.div`
  margin-bottom: 16px;
  
`;