import styled, { css } from "styled-components";
import { defaultBreakpoints } from "styled-media-query";

type StyledProps = {
  inputStyles?: string;
};

export const InputFrostedStyle = css`
  border-radius: 20px;
  background-color: ${({ theme }) => theme.default.color.backgrounds.white};
  border: ${({ theme }) => `1px solid ${theme.default.color.text.light}`};
  box-shadow: ${({ theme }) => `0 0 0 0 ${theme.default.color.text.light}`}
    inset;
  -webkit-appearance: none;
  height: 40px;
`;

export const formInput = css`
  height: 40px;
  border-radius: 20px;
`;

export const formDefault = css`
  height: 40px;
  border-radius: 20px;
`;

export const InputStyled = styled.input<StyledProps>`
  background-color: ${({ theme }) => theme.default.color.backgrounds.white};
  font-family: ${({ theme }) => theme.default.fontFamily.primaryFont};
  outline: 0;
  width: 100%;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  border: ${({ theme }) => `1px solid ${theme.default.color.text.light}`};
  padding: 20px;
  border-radius: 20px;
  box-sizing: border-box;
  
 &:focus {
    border: ${({ theme }) =>  `2px solid ${theme.default.color.primary}`};
  }

  &:hover {
   border: ${({ theme }) =>  `1px solid ${theme.default.color.primary}`};
  }
   
  &::placeholder {
    color: grey;
  }
  ${({ inputStyles }) => inputStyles === "default" && formDefault}
  ${({ inputStyles }) => inputStyles === "frosted" && InputFrostedStyle}
  ${({ inputStyles }) => inputStyles === "form" && formInput}
`;

export const InputError = styled.span`
  color: red;
  font-size: 10px;
  padding-left: 1rem;
  position: absolute;
  bottom: 5px;
`;

export const InputBottomError = styled.span`
  color: red;
  font-size: 10px;
  position: absolute;
`;

export const WrapperInputError = styled.div`
  position: relative;
  white-space: nowrap;
`;
export const StyledLabel = styled.p`
  font-weight: bold;
   font-family: ${({ theme }) => theme.default.fontFamily.primaryFont};
  span {
    color: ${({ theme }) => theme.default.color.primary};
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  span {
    margin-right: 1rem;
  }
`;

export const CheckBoxWrapper = styled.div`
  width: 100%;
`;
